<template>
  <div>
    <div class="head-search">
      <head-layout head-title="我的分享"></head-layout>
      <searchCommon @searchGrid="searchGrid" @searchClass="searchClass" @searchGridAll="searchGridAll" />
    </div>
    <div class="tag-content">
      <knowledge-classification
        v-if="showClassification"
        ref="knowledgeClass"
        @select-tag="selectTag"
      ></knowledge-classification>
    </div>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @grid-row-detail-click="rowView"
      @page-current-change="onLoad"
      @page-size-change="onLoad"
    >
      <template #customBtn="{ row }">
        <el-button type="text" size="medium" @click="deleteShare(row)">
          取消分享
        </el-button>
      </template>
    </grid-layout>
  </div>
</template>

<script>
import GridLayout from "@/views/components/layout/grid-layout";
import knowledgeClassification from "@/views/business/knowledge/components/knowledge-classification";
import {listShare, removeBrowse, removeShare} from "@/api/knowledgeClassifcation/knowledge";
import HeadLayout from "@/views/components/layout/head-layout";
import searchCommon from '@/views/business/knowledge/components/searchCommon.vue';
export default {
  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      knowledgeType: "",
      fileName: "",
      fileTypeId: "",
      showClassification: false
    };
  },

  components: {
    knowledgeClassification,
    GridLayout,
    HeadLayout,
    searchCommon
  },

  computed: {
    tableOptions() {
      return {
        index: true,
        indexLabel: "序号",
        selection: false,
        linklabel: "fileName",
        rowKey: 'shareId',
        column: [
          {
            label: "名称",
            align: "left",
            prop: "fileName",
            overHidden: true,
          },
          {
            label: "文档类型",
            prop: "extension",
            align: "center",
            width: 120,
            overHidden: true,
          },
          {
            label: "上传时间",
            prop: "updateTime",
            align: "center",
            width: 180,
            overHidden: true,
          },
          {
            label: "分享连接",
            prop: "shareLink",
            overHidden: true,
            align: "center",
            width: 240,
          },
        ],
      };
    },
  },

  mounted() {
    this.onLoad(this.page);
  },

  methods: {
    searchGrid(val){
      this.fileName = val;
      this.onLoad(this.page);
    },
    searchClass(val){
      this.knowledgeType = val
      if (val) {
        this.showClassification = true;
        this.$nextTick(() => {
          this.$refs.knowledgeClass.showList = [];
          this.$refs.knowledgeClass.selectTags = [];
          this.selectTag(this.$refs.knowledgeClass.selectTags);
          if(val == 'all') val = ''
          this.$refs.knowledgeClass.getList(val);
        })
      }
    },
    searchGridAll(val){
      this.knowledgeType = null
      this.showClassification = false;
      this.fileName = val;
      this.onLoad(this.page);
    },
    rowView(row) {
      window.open(window.location.origin + `#/knowledgeBrowse?id=${row.id}&fileName=${row.fileName}&isNewWindow=true`);
    },
    onLoad(page) {
      this.page = page;
      this.tableLoading = true;
      listShare(
        page.currentPage,
        page.pageSize,
        this.fileName,
        this.fileTypeId,
        this.knowledgeType
      ).then((res) => {
        const data = res.data.data;
        this.page.total  = data.total;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    selectTag(list) {
      let fileTypeId = [];
      if (list.length > 0) {
        list.forEach((item) => {
          fileTypeId.push(item.id);
        });
      }
      this.fileTypeId = fileTypeId.toString();
      this.onLoad(this.page);
    },
    deleteShare(row) {
      this.$confirm("确定将此条分享记录删除？", {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          removeShare(row.shareId).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.onLoad(this.page);
            }
          });
        })

    },
  },
};
</script>

<style scoped lang='scss'>
.head-search {
  position: relative;
  .el-input {
    width: 300px;
    position: absolute;
    left: 20%;
    top: 6px;
  }
}
.tag-content {
  padding: 5px 12px;
  background: #fff;
}
//::v-deep .avue-crud .el-table {
//  height: calc(100vh - 490px) !important;
//  max-height: calc(100vh - 490px) !important;
//}
</style>
